import JQuery from "jquery";
import { TNanomonxFormConfig } from "../types/TNanomonxFormConfig";
import "../../../resources/less/components/NanomonxForm.less";
import "intl-tel-input/build/css/intlTelInput.css";

export default class NanomonxForm {
  private readonly _formElement: JQuery<HTMLFormElement>;
  private readonly _submitButton: JQuery<HTMLButtonElement>;
  private readonly config: TNanomonxFormConfig;
  private readonly translatedFields: JQuery<HTMLElement>;
  private readonly optionalFieldCbs: JQuery<HTMLInputElement>;
  private readonly translatedValues: Map<string, JSON>;
  private readonly _currentEnv: string;

  constructor(config: TNanomonxFormConfig) {
    this._formElement = $(`form[name='${config.elementName}']`);
    this._currentEnv = $("#app-env").data("appEnv");
    this._submitButton = $(config.submitClass);
    this.translatedFields = $(".translated-field");
    this.optionalFieldCbs = $(".optional-field-cb");
    this.translatedValues = new Map<string, JSON>();
    if (this._formElement.length !== 1) {
      console.error(
        "An exception occurred while generating the form, its provided name is incorrect.",
      );
      return;
    }
    if (!config.allowMultiSubmitBtn && this._submitButton.length !== 1) {
      console.error(
        "An exception occurred while generating the form, its provided button name  is either incorrect or bound on multiple elements",
      );
      return;
    }
    this.config = config;

    for (let i = 0; i < this._formElement[0].elements.length; i++) {
      const element = this._formElement[0].elements.item(i);
      $(element).on("keyup keypress blur change", () => {
        this.checkFieldValidity($(element));
        this.checkFormValidity();
      });
    }

    this.translatedFields.on("keyup keypress blur change", (event) => {
      this.updateTranslation(event.currentTarget);
    });

    for (const field of this.translatedFields) {
      this.updateTranslation(field);
    }

    this.optionalFieldCbs.on("change", (event) => {
      this.updateOptionalField(event.currentTarget);
    });

    this._submitButton.on("click", (event) => {
      if (this.config.onSubmit) {
        event.preventDefault();
        this.config.onSubmit();
        return;
      }
      this._formElement.trigger("submit");
    });
    this.checkFormValidity();
  }

  public get formElement(): JQuery<HTMLFormElement> {
    return this._formElement;
  }

  public get submitButton(): JQuery<HTMLButtonElement> {
    return this._submitButton;
  }

  public get currentEnv(): string {
    return this._currentEnv;
  }

  private _formData: FormData;

  public get formData(): FormData {
    if (this._formData === undefined) {
      this._formData = new FormData(this._formElement[0]);
    }
    return this._formData;
  }

  public Reset(): void {
    this._formElement[0].reset();
  }

  public GetField(fieldName: string): JQuery<HTMLInputElement> {
    if (!this.formData.has(`${this.config.elementName}[${fieldName}]`)) {
      console.error(
        `${this.config.elementName}[${fieldName}] is not present in current form.`,
      );
      return null;
    }

    return this.formElement.find(
      `[name='${this.config.elementName}[${fieldName}]']`,
    ) as JQuery<HTMLInputElement>;
  }

  private checkFieldValidity(element: JQuery<Element>): boolean {
    const errorMessage = element.parent().find(".field-error-msg");
    const label = element.parent().find("label");

    if (errorMessage) {
      errorMessage.toggleClass("hide", element.is(":valid"));
    }
    label.toggleClass("isValid", element.is(":valid"));
    label.toggleClass("isInvalid", !element.is(":valid"));
    element.toggleClass("isValid", element.is(":valid"));
    element.toggleClass("isInvalid", !element.is(":valid"));
    return element.is(":valid");
  }

  private checkFormValidity() {
    if (
      this.config.withValidation !== null &&
      this.config.withValidation === false
    ) {
      return;
    }
    if (this.config.areAdditionalChecksPassing) {
      this.config.areAdditionalChecksPassing();
      this._submitButton.toggleClass(
        "disabled",
        !this._formElement.is(":valid") ||
          !this.config.areAdditionalChecksPassing(),
      );
      if (this.config.onValidityCheck) {
        this.config.onValidityCheck(
          this._formElement.is(":valid") &&
            this.config.areAdditionalChecksPassing(),
        );
      }
    } else {
      this._submitButton.toggleClass(
        "disabled",
        !this._formElement.is(":valid"),
      );
      if (this.config.onValidityCheck) {
        this.config.onValidityCheck(this._formElement.is(":valid"));
      }
    }
  }

  /**
   * In the support there is often fields that are translated like names and stuff.
   * Coupled with TranslatedField.twig, the form is updated automatically with this method
   * @param translatedField
   * @private
   */
  private updateTranslation(translatedField: HTMLElement): void {
    const { target, language } = translatedField.dataset;
    const targetElement: JQuery<HTMLInputElement> = $(
      `#${this.config.elementName}_${target}`,
    );
    if (!this.translatedValues.has(target)) {
      this.translatedValues.set(target, {} as JSON);
    }
    const value = this.translatedValues.get(target);
    value[language] = $(translatedField).find("input")[0].value;
    this.translatedValues.set(target, value);
    targetElement[0].value = JSON.stringify(this.translatedValues.get(target));
  }

  /**
   * Handle checkboxes associated to a form field and set it to disabled if not checked
   * Coupled with OptionalField.twig
   * @param optionalField
   * @private
   */
  private updateOptionalField(optionalField: HTMLInputElement): void {
    const { checked } = optionalField;
    const container = $(optionalField).closest(".optional-field-container");
    const widget = container.find(
      ".optional-field-widget input",
    ) as JQuery<HTMLInputElement>;
    const { type } = widget[0];
    if (checked) {
      widget.removeAttr("disabled");
    } else {
      widget.attr("disabled", "disabled");
      widget.val(type === "color" ? "#000000" : "");
    }
  }
}
