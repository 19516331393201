import "jquery/src/jquery";
import "@popperjs/core/dist/umd/popper.min";
import "bootstrap/dist/js/bootstrap.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "./components/FontAwesomeConfig";
import "../resources/less/bootsrap_add_offset_right.less";
import "../resources/less/Admin/components/Footer.less";
import "../resources/less/components/ShowcaseHeader.less";
import Nanotoast from "./components/NanoToast";
import InputFilters from "./components/InputFilters";
import ViewportZoom from "./ViewportZoom";
import InitBugsnag from "./Bugsnag";
import Navigation from "./components/Navigation";
import Tooltip from "./Tooltip";
import RestPromise from "./utils/RestPromise";
import SwitchToFamilyPopup from "./components/Popups/SwitchToFamilyPopup";

export abstract class BaseMain {
  private errorToast: Nanotoast;
  private successToast: Nanotoast;

  constructor() {
    new Navigation();
    ViewportZoom();
    InitBugsnag();

    new InputFilters();
    new Tooltip();
    new SwitchToFamilyPopup();

    localStorage.setItem("lang", $("#lang")[0].innerText);
    if (localStorage.getItem("country_initialized") === null) {
      RestPromise.Create("/rest/country/initialize", "POST", []).then(() => {
        localStorage.setItem("country_initialized", "true");
      });
    }

    // Block the drag and drop of images
    const icon = $("img");
    icon.on("dragstart", (e) => {
      e.preventDefault();
    });

    icon.on("drop", (e) => {
      e.preventDefault();
    });

    Math.trunc =
      Math.trunc ||
      function (x) {
        if (isNaN(x)) {
          return NaN;
        }
        if (x > 0) {
          return Math.floor(x);
        }
        return Math.ceil(x);
      };

    if ($("#nanotoast-error")[0] !== undefined) {
      this.errorToast = new Nanotoast({
        parentContainer: "body",
        pos: "nanotoast-top-center",
        type: "error",
        text: "#nanotoast-error",
        asideIcon: "fas fa-bug",
        duration: 300,
        hideDelay: 3000,
      });
      this.errorToast.Show();
    }

    if ($("#nanotoast-success")[0] !== undefined) {
      this.successToast = new Nanotoast({
        parentContainer: "body",
        pos: "nanotoast-top-center",
        type: "success",
        text: "#nanotoast-success",
        asideIcon: "fas fa-dove",
        duration: 300,
        hideDelay: 3000,
      });
      this.successToast.Show();
    }
  }
}
