import tippy, { Placement } from "tippy.js";

export default class Tooltip {
  constructor() {
    const tooltipElements = $('[data-toggle="tooltip"]');

    for (let i = 0; i < tooltipElements.length; i++) {
      this.addTooltip(tooltipElements[i]);
    }
  }

  public static Disable(element: HTMLElement) {
    const disableEvent = new CustomEvent("tooltip-disable");
    element.dispatchEvent(disableEvent);
  }

  public static Enable(element: HTMLElement) {
    const enableEvent = new CustomEvent("tooltip-enable");
    element.dispatchEvent(enableEvent);
  }

  private addTooltip(element: HTMLElement) {
    const { placement } = element.dataset;
    const title = element.title;
    const theme = $(element).attr("data-theme")
      ? $(element).attr("data-theme")
      : "nanomonx";
    const instance = tippy(element, {
      content: title,
      placement: placement as Placement,
      animation: "shift-away-extreme",
      duration: [500, 0],
      allowHTML: true,
      theme: theme,
      touch: "hold",
      popperOptions: {
        strategy: "fixed",
        modifiers: [
          {
            name: "flip",
            options: {
              fallbackPlacements: ["bottom", "right"],
            },
          },
        ],
      },
      onShow: (instance) => {
        // Hide the tooltip on scroll if inside a tippy-scroll-container
        const scrollableContainer = instance.reference.closest(
          ".tippy-scroll-container",
        );
        if (scrollableContainer) {
          $(scrollableContainer).on("scroll.tippy", () => {
            instance.hide();
          });
        }
      },
      onHidden: (instance) => {
        const scrollableContainer = instance.reference.closest(
          ".tippy-scroll-container",
        );
        if (scrollableContainer) {
          $(scrollableContainer).off("scroll.tippy");
        }
      },
    });

    // Make sure that the default tooltip is not displayed but only ours
    $(element).removeAttr("title");
    // This allows us to still display a tooltip when the element is disabled
    $(element).on("tooltip-enable", () => {
      instance.enable();
      $(element).css("pointer-events", "auto");
      $(element).off("mouseenter.tooltip touchstart.tooltip");
      $(element).off("mouseleave.tooltip touchend.tooltip");
      $(element).on("mouseenter.tooltip touchstart.tooltip", () => {
        if (!instance.state.isShown) {
          instance.show();
        }
      });

      $(element).on("mouseleave.tooltip touchend.tooltip", () => {
        instance.hide();
      });
    });

    $(element).on("tooltip-disable", () => {
      $(element).off("mouseenter.tooltip touchstart.tooltip");
      $(element).off("mouseleave.tooltip touchend.tooltip");
      instance.disable();
    });
  }
}
