class InputFilters {
  private passwordsInputs: JQuery<HTMLInputElement>;
  private numericInputs: JQuery<HTMLInputElement>;
  private floatInputs: JQuery<HTMLInputElement>;

  constructor() {
    this.passwordsInputs = $('input[type="password"]');
    this.numericInputs = $('input[type="number"]');
    this.floatInputs = $('input[type="float"]');
    /**
     * Makes sure that numeric inputs (input type='number') only have numeric values
     * Mandatory for IE, FF and IOS support
     */
    this.numericInputs.on("keypress", (event) => {
      this.filterChars(event, event.key.charCodeAt(0));
    });
    this.numericInputs.on("change", (event) => {
      this.filterChars(event, event.currentTarget.value.charCodeAt(0));
      this.filterNumerics(event);
    });

    this.passwordsInputs.on("keypress", (event) => {
      this.filterPasswords(event);
    });

    this.floatInputs.on("keypress", (event) => {
      this.filterChars(event, event.key.charCodeAt(0));
    });
    this.floatInputs.on("change", (event) => {
      this.filterFloats(event);
    });
  }

  private filterFloats(event: JQuery.ChangeEvent | JQuery.KeyPressEvent): void {
    const value = event.target.value;
    const parsedValue = parseFloat(value);

    if (isNaN(parsedValue)) {
      event.preventDefault();
    } else {
      event.target.value = parsedValue;
    }

    if (event.target.max !== "" && parsedValue > parseFloat(event.target.max)) {
      event.target.value = event.target.max;
    }
    if (
      event.target.min !== "" &&
      event.target.value * 1 < parseFloat(event.target.min)
    ) {
      event.target.value = event.target.min;
    }
  }

  /**
   * Only accept alphanumeric values and special characters for passwords to avoid issues with unity
   */
  private filterPasswords(event: JQuery.KeyPressEvent): void {
    const key = event.key.charCodeAt(0);
    if (
      key !== 46 &&
      key !== 8 &&
      key !== 13 /** Backspace and delete and enter */ &&
      !(key > 31 && key < 126)
    ) {
      /** US keyboard values */

      event.preventDefault();
    }
  }

  /**
   * Makes sure that the numeric input is between its min and max values
   * Mandatory for IE, FF and IOS support
   */
  private filterNumerics(event: JQuery.ChangeEvent): void {
    const value = event.target.value;
    let input = "";
    for (let i = 0; i < value.length; i += 1) {
      if (
        value[i].charCodeAt(0) === 45 ||
        value[i].charCodeAt(0) === 46 ||
        (value[i].charCodeAt(0) > 47 && value[i].charCodeAt(0) < 58)
      ) {
        input += value[i];
      }
    }
    if (+input === 0 && value !== "0") {
      event.target.value = "";
    } else {
      event.target.value = +input;
    }

    if (event.target.max !== "" && +event.target.value > +event.target.max) {
      event.target.value = event.target.max;
    }
    if (event.target.min !== "" && +event.target.value < +event.target.min) {
      event.target.value = event.target.min;
    }
  }

  private filterChars(
    event: JQuery.ChangeEvent | JQuery.KeyPressEvent,
    key: number,
  ): void {
    if (
      key !== 46 &&
      key !== 8 /** Backspace and delete */ &&
      !(key > 47 && key < 58) /** Numeric */ &&
      key !== 9
    ) {
      /** Tab */
      event.preventDefault();
    }

    /**
     * Makes sure that our numeric inputs doesn't have any negative values
     */
    if (event.currentTarget.value < 0) {
      event.currentTarget.value = 0;
    }
  }
}

export default InputFilters;
