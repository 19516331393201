import anime from "animejs/lib/anime.es";

class NanoToast {
  constructor($options) {
    const $param = {
      parentContainer: "body",
      pos: "nanotoast-bottom-center",
      text: "",
      asideIcon: null,
      type: "info",
      button: {
        text: "",
        url: "#",
        target: "self",
      },
      zIndex: null,
      hideDelay: null,
      showDelay: null,
      duration: 300,
      customClass: "",
      before() {},
      enter() {},
      hide() {},
    };
    this.colorsType = ["info", "warning", "success", "error"];
    this.timeout = null;
    this.param = $param;
    this.options = $options;
    // Button set to null by default;
    this.param.button = null;
  }

  static StringType(str) {
    // Will return true if it's a string without class/Id prefix
    const string =
      typeof str === "string" &&
      str.trim() &&
      !str.startsWith(".") &&
      !str.startsWith("#");
    return string;
  }

  Hide() {
    this.animateOut(this.options, this.nanoToast);
  }

  Show($customParam) {
    // Clearing the current timeout animationOut
    clearTimeout(this.timeout);
    let options = $.extend({}, this.param, this.options);
    options = $.extend({}, options, $customParam);
    this.options = options;

    // Check if there is allready an instance of that object,
    // if to many it will return to prevent spam
    if ($(`.${options.pos}`).length > 1) {
      return;
    }
    if ($(`.${options.pos}`).length > 0) {
      const instance = $(document).find(`.${options.pos}`);
      this.animateOut(options, instance[0]);
    }

    // Parent toast
    this.nanoToast = document.createElement("div");
    this.nanoToast.className = `nanotoast-container ${options.customClass} ${
      options.pos
    } ${this.GetColorType(options.type)}`;
    if ($(".container-fluid").hasClass("pushed")) {
      this.nanoToast.classList.add("pushed-fixed-ui");
    }
    if ($(".container-fluid").hasClass("not-license")) {
      this.nanoToast.classList.add("with-menu");
    }

    this.nanoToast.style.backgroundColor = this.GetColorType(options.type);
    if (this.options.zIndex !== null) {
      this.nanoToast.style.zIndex = this.options.zIndex;
    }

    // Inner groupe div
    const $innerDiv = document.createElement("div");
    $innerDiv.className = "inner-div";
    $(this.nanoToast).append($innerDiv);

    // Add close icon
    const $close = document.createElement("i");
    $close.className = "fas fa-times toast--close";
    $($close).click(() => this.Hide());
    $(this.nanoToast).append($close);

    // Add icon if not null
    const $i = document.createElement("i");
    if (options.asideIcon != null) {
      if (options.asideIcon.startsWith("fa")) {
        $i.className = `${options.asideIcon}`;
        $($innerDiv).append($i);
      } else {
        throw new Error(
          `Is this a valid fontawesome prefix? '${options.asideIcon}'`,
        );
      }
    }

    // Adding text
    const $p = document.createElement("p");
    if (NanoToast.StringType(options.text)) {
      $p.innerText = options.text;
    } else if ($(options.text).length > 0) {
      $p.innerText = $(options.text).text().trim();
    } else {
      console.error(`Cannot add text for '${options.text}'`);
    }
    $($innerDiv).append($p);
    // Adding button if not false (true by default)
    if (options.button !== null) {
      this.nanoToast.classList.add("has-button");
      const $button = document.createElement("button");
      if (options.button.class !== undefined) {
        $button.className = options.button.class;
      }
      const $buttonLink = document.createElement("a");
      $buttonLink.target = options.button.target
        ? options.button.target
        : "_self";

      // Looking for HREF element, if it's a div or a string
      if (NanoToast.StringType(options.button.url)) {
        $buttonLink.href = `${options.button.url}`;
        $buttonLink.id = "nano-toast-link";
      } else if ($(options.button.url).length > 0) {
        $buttonLink.href = $(options.button.url).attr("href");
        $buttonLink.id = "nano-toast-link";
      } else {
        options.button.url = null;
      }

      // Looking for text element, if it's a div or a string
      if (NanoToast.StringType(options.button.text)) {
        $button.innerText = options.button.text;
      } else if ($(options.button.text).length > 0) {
        $button.innerText = $(options.button.text).text();
        options.button.target = $button;
      } else {
        throw new Error(`Cannot add button text for '${options.button.text}'`);
      }

      $($buttonLink).append($button);
      $($innerDiv).append($buttonLink);

      // add button click kevent
      this.options = $.extend({}, this.options, () => {});

      $($button).on("click", () => {
        if (jQuery.isFunction(this.options.click)) {
          this.options.click(this);
        }
      });
    }

    // Prepend toast to the container
    if ($(options.parentContainer).length) {
      $(options.parentContainer).prepend(this.nanoToast);
    } else {
      throw new Error(
        `Cannot fint the parentContainer of '${options.parentContainer}'`,
      );
    }

    // Give a small delay to let last toast fadeOut
    // Prevent spamming glitch
    this.delay = 0;
    if (options.showDelay != null) {
      this.delay = options.showDelay;
    }

    this.timeout = window.setTimeout(() => {
      // Return callback before animation
      options.before(this.nanoToast);
      this.animateIn(options);
      clearTimeout(this.timeout);
    }, this.delay);
  }

  animateIn(options) {
    const animation = anime.timeline({
      targets: this.nanoToast,
      easing: "easeOutQuint",
      duration: options.duration,
      opacity: "1",
    });
    if (options.pos.startsWith("nanotoast-bottom")) {
      animation.add({
        bottom: "0px",
      });
    }
    if (options.pos.startsWith("nanotoast-top")) {
      animation.add({
        top: "87px",
      });
    }
    animation.finished.then(() => {
      options.enter(this.nanoToast);
      if (options.hideDelay === null) {
        return;
      }
      this.timeout = window.setTimeout(() => {
        this.animateOut(options, this.nanoToast);
        clearTimeout(this.timeout);
      }, options.hideDelay);
    });
  }

  animateOut(options, instance) {
    const animation = anime.timeline({
      targets: instance,
      easing: "easeOutQuint",
      duration: options.duration,
      opacity: "0",
    });
    if (options.pos.startsWith("nanotoast-bottom")) {
      animation.add({
        bottom: "-15px",
      });
    }
    if (options.pos.startsWith("nanotoast-top")) {
      animation.add({
        top: "15px",
      });
    }
    animation.finished.then(() => {
      this.removeInstance(options, instance);
    });
  }

  removeInstance(options, instance) {
    $(instance).remove();
    // Return callback after removing object
    options.hide(this.nanoToast);
  }

  GetColorType(type) {
    for (let i = 0; i < this.colorsType.length; i += 1) {
      if (type === this.colorsType[i]) {
        return this.colorsType[i];
      }
    }
    throw new Error(`Can't find the color type for '${type}'`);
  }
}

export default NanoToast;
