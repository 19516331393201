import "jquery/src/jquery";
import LoginPopup from "./Admin/LoginPopup";
import { BaseMain } from "./BaseMain";
import "../resources/less/Admin/LoggedOut/ResendEmail.less";

class LoggedOutMain extends BaseMain {
  constructor() {
    super();
    new LoginPopup();
  }
}

$(window).on("load", () => {
  new LoggedOutMain();
});
