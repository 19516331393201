async function ViewportZoom(): Promise<void> {
  new Promise(() => {
    // When the viewport width is below the minimum supported width
    // of 375px the viewport scale will be adjusted down by 20%.
    // This intervention will resolve a display issue caused by
    // the iOS "Zoom Display / Larger Text" setting on devices
    // where that setting results in the viewport width dropping
    // below the minimum supported width of 375.
    const windowWidth = document.documentElement.clientWidth;
    if (windowWidth < 375) {
      const viewport = document.querySelector("meta[name=viewport]");
      viewport.setAttribute(
        "content",
        "width=device-width, initial-scale=0.8, minimum-scale=0.8, maximum-scale=0.8, user-scalable=0",
      );
    }
  });
}

export default ViewportZoom;
