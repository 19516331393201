import NanoPopup from "../NanoPopup";

export default class NanoPopupHiddenEvent extends Event {
  private readonly _paywallPopup: boolean;
  private readonly _popup: NanoPopup;
  private readonly _fromPaywall: boolean;

  constructor(popup: NanoPopup, fromPaywall: boolean) {
    super("nanopopup-hidden");
    this._popup = popup;
    this._fromPaywall = fromPaywall;
    this._paywallPopup = popup.config.target == "#paid-feature-info-popup";
  }

  public get fromPaywall(): boolean {
    return this._fromPaywall;
  }

  public get paywallPopup(): boolean {
    return this._paywallPopup;
  }

  public get popup(): NanoPopup {
    return this._popup;
  }
}
