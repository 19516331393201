import NanoPopup from "./NanoPopup";
import "../../../resources/less/Family/Popups/SwitchToTeacherVersionPopup.less";

export default class SwitchToFamilyPopup extends NanoPopup {
  private popupContainer: JQuery<HTMLElement>;

  constructor() {
    super({
      target: "#switch-to-family-popup",
    });
    this.popupContainer = $(".switch-to-family-popup");

    if (this.popupContainer.length > 0) {
      $(".family-mode-button").on("click", () => {
        super.Show();
      });
    }
  }
}
