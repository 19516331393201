import Nanotoast from "../components/NanoToast";
import Bugsnag from "@bugsnag/js";

/**
 * A utility class with a collection of methods surrounding REST API calls
 * and the functions we commonly use to deal with those.
 */
class RestPromise {
  /**
   * This is a sort of factory method for an Ajax request
   * @param url
   * @param method
   * @param content
   * @param notifyActivity
   * @returns Promise
   */
  public static async Create<T = any>(
    url: string,
    method: "GET" | "PUT" | "POST" | "DELETE",
    content: object = null,
    notifyActivity = true,
  ): Promise<T> {
    if (notifyActivity) {
      $(window).trigger("user-activity");
    }

    let promise;
    if (content !== null) {
      promise = $.ajax({
        type: method,
        url: url,
        data: JSON.stringify(content),
        contentType: "application/json",
        dataType: "json",
      });
    } else {
      promise = $.ajax({
        type: method,
        url: url,
        contentType: "application/json",
        dataType: "json",
      });
    }

    return promise.catch((error) => {
      if (!error.ok && error.statusText === "Forbidden") {
        return RestPromise.Create("/rest/is-logged-in", "GET")
          .then((response) => {
            if (!response.loggedIn) {
              window.location.href = "/logout?logout-reason=second-login";
            }
          })
          .then(() => {
            throw error;
          });
      } else {
        throw error;
      }
    });
  }

  public static DisplayRestSuccess(message: string): void {
    const successToast = new Nanotoast({
      parentContainer: "body",
      pos: "nanotoast-top-center",
      type: "success",
      text: message,
      asideIcon: "far fa-thumbs-up",
      duration: 300,
      hideDelay: 5000,
    });
    successToast.Show();
  }

  public static DisplayRestError(error): void {
    if (+error.status !== 500 && +error.status !== 0) {
      if (
        +error.status == 403 &&
        error.responseText.includes("login_token_invalid")
      ) {
        window.location.href = "/logout?logout-reason=second-login";
        return;
      }

      let text;

      //Add support for the default Symfony exceptions
      if ("responseJSON" in error && "message" in error.responseJSON) {
        text = error.responseJSON.message;
      } else if ("responseJSON" in error) {
        text = error.responseJSON.detail;
      } else {
        //This is for our RestException class
        text = error.responseText;
      }
      const errorToast = new Nanotoast({
        parentContainer: "body",
        pos: "nanotoast-top-center",
        type: "error",
        text: text,
        asideIcon: "fas fa-bug",
        duration: 300,
        hideDelay: 5000,
        zIndex: 350,
      });
      errorToast.Show();
    } else {
      Bugsnag.notify(error);
    }
  }

  public static DisplayRestMessage(
    message: string,
    className: string,
    icon = "fa-bug",
  ) {
    const errorToast = new Nanotoast({
      parentContainer: "body",
      pos: "nanotoast-top-center",
      type: className,
      text: message,
      asideIcon: `fas ${icon}`,
      duration: 300,
      hideDelay: 5000,
      zIndex: 350,
    });
    errorToast.Show();
  }

  public static DisplayRestWarning(message: string) {
    const errorToast = new Nanotoast({
      parentContainer: "body",
      pos: "nanotoast-top-center",
      type: "warning",
      text: message,
      asideIcon: "fas fa-exclamation",
      duration: 300,
      hideDelay: 5000,
      zIndex: 350,
    });
    errorToast.Show();
  }

  /**
   * Ping the server, returns true if the server answer a 200 response
   * @constructor
   */
  public static RequestServerHealth(): Promise<boolean> {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", "/health", true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.onload = () => {
        resolve(xhr.status === 200);
      };
      xhr.timeout = 2000;
      xhr.ontimeout = () => resolve(false);
      xhr.onerror = () => resolve(false);
      xhr.send();
    });
  }
}

export default RestPromise;
